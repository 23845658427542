"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var app = {
  setCookie: function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  getCookie: function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  mobileMenu: function mobileMenu() {
    var mobileBtn = $("#mobile-burger-btn");
    var pageBody = $("#page-wrap-inner");

    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
        } else {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
        }
      });
    }
  },
  megaMenu: function megaMenu() {
    var parent = $("header nav a.hasChildren");

    parent.click(function (e) {
      e.preventDefault();
      var topLevel = $(this).parent("li");
      var subMenu = $(topLevel).find(".subMenu");
      if (subMenu.hasClass("hidden")) {
        $(".subMenu").addClass("hidden");
        subMenu.removeClass("hidden");
      } else {
        subMenu.addClass("hidden");
      }

      //subMenu.removeClass("hidden");
    });
  },
  validateEmail: function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  check3dSecure: function check3dSecure(e) {
    e.preventDefault();
    var checkIf3D = $('#xid');
    var form = $('.payment_form');
    var base_url = window.location.protocol + "//" + window.location.host + "/";

    if (checkIf3D.length == 0) {
      $('#3d-secure-frm').removeClass('hidden');
      $.ajax({
        url: '/3ds/check3dsVersion',
        type: "POST",
        data: {
          _token: $('input[name="_token"]').val(),
          _params: $('input[name="_params"]').val(),
          card: {
            number: $('#cardnumber').val(),
            expMonth: $('#expiry_mon').val(),
            expYear: $('#expiry_yr').val(),
            cvn: $('#cvv').val(),
            cardHolderName: $('#cardholder').val()
          },
          amount: $('#amount').val(),
          currency: $('#currency').val()
        }
      }).done(function (response) {
        var enrolled = response.enrolled;
        if (enrolled == "Y") {
          var acsUrl = response.acsUrl;
          var paReq = response.paReq;
          var md = response.md;
          var target = '/3ds/authorise';
          var submitted = false;
          var html = "";
          html += "<style>form { background-color:white; position:absolute; top:calc(50% - 40px); } .lds-ellipsis{display:inline-block;position:relative;width:80px;height:80px;margin-left:auto;margin-right:auto;}.lds-ellipsis div{position:absolute;top:33px;width:13px;height:13px;border-radius:50%;background:#333;animation-timing-function:cubic-bezier(0,1,1,0)}.lds-ellipsis div:nth-child(1){left:8px;animation:lds-ellipsis1 .6s infinite}.lds-ellipsis div:nth-child(2){left:8px;animation:lds-ellipsis2 .6s infinite}.lds-ellipsis div:nth-child(3){left:32px;animation:lds-ellipsis2 .6s infinite}.lds-ellipsis div:nth-child(4){left:56px;animation:lds-ellipsis3 .6s infinite}@keyframes lds-ellipsis1{0%{transform:scale(0)}100%{transform:scale(1)}}@keyframes lds-ellipsis3{0%{transform:scale(1)}100%{transform:scale(0)}}@keyframes lds-ellipsis2{0%{transform:translate(0,0)}100%{transform:translate(24px,0)}}</style>";
          html += "<div style=\"text-align:center;position: absolute;top: calc(50% - 40px);left: 0;width: 100%;\"><div class=\"lds-ellipsis\"><div></div><div></div><div></div><div></div></div></div>";
          html += '<form id="3dsecure-frm" action="' + acsUrl + '" method="POST">';
          html += '<input type="hidden" id="PaReq" name="PaReq" value="' + paReq + '" />';
          html += '<input type="hidden" id="TermUrl" name="TermUrl" value="' + base_url + '3ds/verify" />';
          html += '<input type="hidden" id="MD" name="MD" value="' + md + '" />';
          html += '<noscript>';
          html += '<input type="submit" value="Submit />';
          html += '</noscript>';
          html += '</form>';
          $('#3d-secure-frm').html("<div><iframe id='3d-secure-frm-iframe' width='850' height='450' frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src='" + target + "'></iframe></div>");
          $("#3d-secure-frm-iframe").on('load', function (e) {
            var iframe = $('#3d-secure-frm-iframe').contents();
            if (submitted == false) {
              iframe.find("body").append(html);
              iframe = $('#3d-secure-frm-iframe').contents();
              iframe.find("#3dsecure-frm").submit();
              submitted = true;
            }
          });
        } else if (enrolled == "N") {
          // NOT ENROLLED
          form[0].append('<input type="hidden" name="xid" id="xid" value="" />'); // blank xid to proceed
          form[0].submit();
        } else {
          if ($('#validation-error').length == 0) {
            $('#validation-error').remove();
          }
          var error = '<div id="validation-error" class="validation-msg error">We could not proceed with payment.  Please try a different card</div>';
          $(error).insertBefore(form[0]);

          $('#3d-secure-frm').addClass('hidden');
          $('html,body').animate({
            scrollTop: $("#validation-error").offset().top - 10
          }, 500);
        }
      });
    }
  },

  slugify: function slugify(string) {
    var a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    var b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz______';
    var p = new RegExp(a.split('').join('|'), 'g');

    return string.toString().toLowerCase().replace(/\s+/g, '_') // Replace spaces with -
    .replace(p, function (c) {
      return b.charAt(a.indexOf(c));
    }) // Replace special characters
    .replace(/&/g, '_and_') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '_') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
  },

  privacyNotification: function privacyNotification() {
    var privacy = $('#privacyNotification');
    var privacyBtn = $('.notificationToggle');
    var exists = app.getCookie('privacy');

    if (exists != 1) {
      $('.privacy-area').removeClass('hidden');
    }

    privacyBtn.click(function (e) {
      $('.privacy-area').toggleClass('hidden');
      e.preventDefault();
      app.setCookie('privacy', 1, 30);
    });
  },

  isPopupBlocked: function isPopupBlocked() {
    var testPopup = window.open('', '_blank', 'width=1, height=1, top=9999, left=9999');
    if (!testPopup) {
      return true;
    } else {
      testPopup.close();
      return false;
    }
  },

  showWarning: function showWarning() {
    // $('#popup-warning').show();
  },

  showWarningPay: function showWarningPay() {
    // $('#popup-warninginwindow').show();
  },

  init: function init() {
    var _$$slick;

    app.mobileMenu();
    app.megaMenu();

    if ($('#cardnumber').length > 0) {
      $('#cardnumber').formatCardNumber();
    }

    if ($('#request_a_callback_form').length > 0) {
      var fp = flatpickr(".date", {
        enableTime: true,
        minTime: "09:00",
        maxTime: "17:00",
        dateFormat: "Y-m-d H:i",
        "disable": [function (date) {
          // return true to disable
          return date.getDay() === 0 || date.getDay() === 6;
        }]
      });
    }

    if ($('#privacyNotification').length > 0) {
      app.privacyNotification();
    }

    $("#slick-case-studies").slick((_$$slick = {
      appendDots: "#testimonialpaging",
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      nextArrow: "#nxt-btn",
      prevArrow: "#prev-btn",
      cssEase: "linear",
      centerMode: true,
      centerPadding: "25%"
    }, _defineProperty(_$$slick, "slidesToShow", 1), _defineProperty(_$$slick, "responsive", [{
      breakpoint: 1300,
      settings: {
        centerPadding: "18%"
      }
    }, {
      breakpoint: 960,
      settings: {
        centerPadding: "5%"
      }
    }, {
      breakpoint: 768,
      settings: {
        centerPadding: "3%"
      }
    }, {
      breakpoint: 600,
      settings: {
        centerPadding: "1.5%"
      }
    }]), _$$slick));

    $("#homeslide").slick({
      appendDots: ".slide-pagination",
      dots: true,
      infinite: true,
      fade: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 4000,
      nextArrow: "#slide-nxt",
      prevArrow: "#slide-prev",
      cssEase: "linear",
      slidesToShow: 1
    });

    $("#slideshow").slick(_defineProperty({
      appendDots: "#slideshowpaging",
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      nextArrow: "#nxt-btn-slide",
      prevArrow: "#prev-btn-slide",
      cssEase: "linear"
    }, "slidesToShow", 1));

    $("#enquire").click(function (e) {
      e.preventDefault();
      var html = $("#enquire-frm").html();
      $.fancybox.open({
        type: "html",
        buttons: ["close"],
        src: html,
        baseTpl: '<div class="fancybox-container" role="dialog" tabindex="-1">' + '<div class="fancybox-bg"></div>' + '<div class="fancybox-inner">' + '<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' + '<div class="fancybox-toolbar">{{buttons}}</div>' + '<div class="fancybox-navigation">{{arrows}}</div>' + '<div class="fancybox-stage"></div>' + '<div class="fancybox-caption"><div class=""fancybox-caption__body"></div></div>' + "</div>" + "</div>",
        opts: {
          animationDuration: 350,
          animationEffect: "material",
          modal: true,
          afterLoad: function afterLoad(instance, current) {
            $(".fancybox-content #submit_enquiry_btn").unbind().click(function (e) {
              e.preventDefault();
              var title = $("#dialogTitle").html();
              $(".fancybox-content #subject").val("Enquiry on " + title);
              var form = $(".fancybox-content form");
              var action = $(form[0]).attr("action");
              var formData = $(form[0]).serialize();
              if ($("#validation-msg").length > 0) {
                $("#validation-msg").remove();
              }
              $.ajax({
                url: action,
                type: "POST",
                data: formData,
                success: function success(data) {
                  if (data.success == true) {
                    var msg = "Thank You for Your Enquiry.  A member of our team will be in touch.";
                    $(form[0]).prepend('<div id="validation-msg" class="validation-msg">' + msg + "</div>");
                    $(".form-item, .terms-wrap-chk, #submit_enquiry_btn").hide();
                  } else {
                    var msg = "This Service is currently unavailable.  Please try again later.";
                    $(form[0]).prepend('<div id="validation-msg" class="validation-msg error">' + msg + "</div>");
                  }
                },
                error: function error(data) {
                  var response = data.responseText;
                  var errors = $.parseJSON(response);
                  errors = errors.errors;

                  var msg = "";
                  $.each(errors, function (key, row) {
                    msg += row + "<br />";
                  });

                  $(form[0]).prepend('<div id="validation-msg" class="validation-msg error">' + msg + "</div>");
                }
              });
            });
          }
        }
      });
    });

    if ($('.toggle-btns').length > 0) {
      $('.toggle-btns span').each(function () {
        var row = $(this);
        row.click(function (e) {
          var select = $(this);
          var step = select.attr('data-step');
          $('.toggle-btns span').attr('data-selected', false);
          select.attr('data-selected', true);
          if (step == 1) {
            $('#pay_for_install').addClass('hidden');
            $('#installation_type').val("Self Install");
          }
          if (step == 2) {
            $('#pay_for_install').removeClass('hidden');
            $('#installation_type').val("TSO Visit");
          }
          $('#ready_to_submit').removeClass('hidden');
        });
      });

      // ONLOAD
      var installation_type = $('#installation_type').val();
      if (installation_type == 'TSO Visit') {
        $('#pay_for_install').removeClass('hidden');
        $('#ready_to_submit').removeClass('hidden');
      } else if (installation_type == 'Self Install') {
        $('#pay_for_install').addClass('hidden');
        $('#ready_to_submit').removeClass('hidden');
      }
    }

    /*
    var payment_form = $('.payment_form');
    if(payment_form.length > 0) {
      payment_form.submit(function(e) {
        app.check3dSecure(e);
      });
    }*/

    if ($('#discount_btn').length > 0) {
      $('#discount_btn').click(function (e) {
        e.preventDefault();
        var discount_code = $("#discount_code").val();
        var notification = $(".discount-notification");
        notification.removeClass('show');
        var array = [];
        $('.discount_live').each(function () {

          array.push($(this).data('code'));
          var live_code = $(this).attr('data-code');
          var price = $(this).attr('data-price');
          var discount = $(this).attr('data-discount');
          var new_total = price - discount;

          if (discount_code === live_code) {

            notification.html("<p>Discount code added successfully</p>");
            notification.addClass('show');
            $('#payment_fee_new').html(new_total);
            $('#price_was').addClass('show');
            $('#payment_fee').addClass('txt-updated');
            $("#amount").val(new_total);
            return false;
          } else {

            $('#price_was').removeClass('show');
            $('#payment_fee').removeClass('txt-updated');
            notification.addClass('show');
            notification.html("<p>Discount code unsuccessfull, please check and try again</p>");
          };
        });
      });
    }

    if ($('#payButtonId').length > 0) {

      var ongoingRequest = null;

      $('#payment_btn').click(function (e) {

        e.preventDefault();

        // Check if CAPTCHA is complete
        var recaptchaResponse = $('#g-recaptcha-response').val();
        if (recaptchaResponse === "" || recaptchaResponse === undefined || recaptchaResponse === null) {
          alert("Please complete the CAPTCHA.");
          return false;
        }

        var form = $("form.payment_form");
        var currency = $('#currency').val();
        var isValid = currency === '0' ? false : true;
        var referenceNumber = $('input[name="reference_number"]').val();

        var amount = $('#amount').val();
        // Update the payment details in the iframe section

        var paymentform = $("#paymentform");
        var paymentIframe = $("#paymentIframe");

        if (isValid === false) {
          $('#curencyvalidation').removeClass('hidden');
          $('html, body').animate({
            scrollTop: $('#amount_section').offset().top - 20
          });
          e.preventDefault();
        }
        if (isValid === true) {

          $('#curencyvalidation').addClass('hidden');
          e.preventDefault();
          $('#payment_btn').prop('disabled', true);
          //$('body').addClass('loaded');
          $('#validation-error').remove();
          var name = $('input[name="name"]').val();
          var order_id = app.slugify(name) + '_' + Date.now();
          $(form[0]).append('<input type="hidden" name="payment_order_id" value="' + order_id + '">'); // append order id to form
          var action = $(form[0]).attr("action");
          var formData = $(form[0]).serialize();
          var formType = $(form[0]).data('type');

          // app.showWarningPay();

          ongoingRequest = $.ajax({
            url: action,
            type: "POST",
            data: formData,
            success: function success(data) {
              if (data.success == true) {
                var paymentFormTrue = function paymentFormTrue() {
                  console.log("set paymentFormTrue");
                  paymentLoaded = true;
                };

                var showRetryButton = function showRetryButton(show) {
                  if (show) {
                    document.getElementById('retryButton').style.display = 'block';
                  } else {
                    document.getElementById('retryButton').style.display = 'none';
                  }
                };

                // trigger connection

                var amount = $('#amount').val();
                amount = $.trim(amount);

                var STREET1 = $('#billing_add1').val();
                var STREET2 = $('#billing_add2').val();
                var CITY = $('#billing_city').val();
                var COUNTRY = $('#billing_country').val();
                var POSTALCODE = $('#billing_postcode').val();
                var CUSTOMER_EMAIL = $('#email_input').val();

                var custPhone = $('#email_phone').val();
                var custName = $('#name_input').val();

                $.getJSON("/payment/init?amount=" + amount + "&currency=" + currency + "&type=" + formType + "&order_id=" + order_id + "&STREET1=" + STREET1 + "&STREET2=" + STREET2 + "&CITY=" + CITY + "&COUNTRY=" + COUNTRY + "&POSTALCODE=" + POSTALCODE + "&CUSTOMER_EMAIL=" + CUSTOMER_EMAIL, function (jsonFromServerSdk) {
                  RealexHpp.setHppUrl(jsonFromServerSdk.ENDPOINT);
                  //RealexHpp.redirect.init("payButtonId", "https://radius24.reflex-dev.com/response.html", jsonFromServerSdk);
                  // RealexHpp.lightbox.init(
                  //   "payButtonId", 
                  //   "/payment/response?order=" + jsonFromServerSdk.ORDER_ID, 
                  //   jsonFromServerSdk); 

                  // When using full page redirect your Response URL must be an absolute link
                  // RealexHpp.redirect.init("payButtonId", "https://radius24.reflex-dev.com/response.html?order=" + jsonFromServerSdk.ORDER_ID, jsonFromServerSdk);


                  RealexHpp.embedded.init("payButtonId", "CardDetailsForm", "/payment/response?order=" + jsonFromServerSdk.ORDER_ID, jsonFromServerSdk);

                  // if (window.addEventListener) {
                  //    window.addEventListener('message', receiveMessage, false);
                  //  } else {
                  //    window.attachEvent('message', receiveMessage);
                  //  }

                  // paymentform.removeClass("show");
                  // paymentform.addClass("hide");
                  // paymentIframe.addClass("show");
                  // paymentIframe.removeClass("hide");


                  $('#paymentform').fadeOut('slow', function () {
                    // Scroll to the payment iframe
                    $('html, body').animate({
                      scrollTop: $('#paymentIframe').offset().top - 20
                    }, 'slow', function () {
                      // Fade in the payment iframe
                      $('#paymentIframe').fadeIn('slow');
                    });
                    console.log("referenceNumber:", referenceNumber);
                    var currencySymbol = currency === 'EUR' ? '€' : '£';
                    $('#paymentIframe').find('#idNumber').text(referenceNumber);
                    $('#paymentIframe').find('#payment_fee_new').text(currencySymbol + amount);
                    $('#paymentIframe').find('#custName').text(custName);
                    $('#paymentIframe').find('#custEmail').text(CUSTOMER_EMAIL);
                    $('#paymentIframe').find('#custPhone').text(custPhone);
                  });
                }).done(function () {
                  $('#payButtonId').trigger('click');
                });

                var paymentLoaded = false;


                $('#CardDetailsForm').on('load', function () {
                  console.log('Payment iframe loaded and /payment/updatestatus?order_id=' + order_id + '&status=PaymentScreen');

                  window.addEventListener('message', function (event) {
                    console.log("Received event:", event);
                    console.log("Event data:", event.data);
                    paymentFormTrue();
                    showRetryButton(false);
                    //could i run a function in here which changes a global variable?
                  }, false);

                  setTimeout(function () {
                    if (!paymentLoaded) {
                      showRetryButton(true);
                      console.log("No payment load event detected within timeout, setting status to Error." + formType);
                      $.getJSON("/payment/updatestatus?order_id=" + order_id + "&formname=" + formType + "&status=Form Input Error", function (response) {}).fail(function (jqxhr, textStatus, error) {
                        console.log("Request Failed: " + textStatus + ", " + error + formType);
                      });
                    } else {
                      console.log("setting status to paymentScreen." + formType);
                      showRetryButton(false);
                      $.getJSON("/payment/updatestatus?order_id=" + order_id + "&formname=" + formType + "&status=Payment Screen Loaded", function (response) {}).fail(function (jqxhr, textStatus, error) {
                        console.log("Request Failed: " + textStatus + ", " + error + formType);
                      });
                    }
                  }, 3000); // Adjust the timeout duration as needed
                });
              } else {
                // form not complete, unable to take payment
                $(form[0]).insertBefore('<div id="validation-error" class="validation-msg error">We could not process your form at this stage.</div>');
                $('#payment_btn').prop('disabled', false);
                $('body').removeClass('loaded');
              }
            },
            error: function error(data) {
              var response = data.responseText;
              var errors = $.parseJSON(response);
              errors = errors.errors;
              $('<div id="validation-error" class="validation-msg error">' + errors.join('<br /') + '</div>').insertBefore($(form[0]));
              $('html, body').animate({
                scrollTop: $('#validation-error').offset().top - 20
              });
              $('#payment_btn').prop('disabled', false);
              $('body').removeClass('loaded');
            }
          });
        }
      });
    }

    // ORDER NOW
    if ($("#complete_additional_btn").length > 0) {
      var btn = $("#complete_additional_btn");
      var additional_information = $("#additional_information");
      btn.click(function (e) {
        e.preventDefault();
        additional_information.fadeIn();
        btn.hide();
        $("#order").hide();
      });
    }

    // Get the current page path
    var path = window.location.pathname;

    // Only show the warning on the specified pages
    // if (path === '/pay-a-bill' || path === '/order-now-form') {
    //   if (this.isPopupBlocked()) {
    //     this.showWarning();
    //   }
    // }

    // $('.close-warning').on('click', function() {
    //   $('#popup-warning').hide();
    // });

    // $('.close-warning-pay').on('click', function() {
    //   $('#popup-warninginwindow').hide();
    // });

    // $('.close-pay-window').on('click', function() {

    //   // Reset UI elements
    //   $('#payment_btn').prop('disabled', false);
    //   $('body').removeClass('loaded');
    //   $('#validation-error').remove();
    //   if (ongoingRequest !== null) {
    //     ongoingRequest.abort();
    //     ongoingRequest = null;
    //   }
    // });
  }
};

// Handle retry button click
$('#retryButton').on('click', function () {
  $(this).hide(); // Hide retry button
  resetForm(); // Show the form and reactivate the submit button
});

function resetForm() {

  $('#paymentIframe').fadeOut('slow', function () {
    // Scroll to the payment iframe
    $('html, body').animate({
      scrollTop: $('#paymentform').offset().top - 20
    }, 'slow', function () {
      // Fade in the payment iframe
      $('#paymentform').fadeIn('slow');
    });
    $('#CardDetailsForm').remove();
    $('#paymentreplace').append('<iframe id="CardDetailsForm" class="relative top-0 left-0 z-20" style="width:100%; height:600px;  background-color: #fff;"></iframe>');
  });

  $('#payment_btn').prop('disabled', false); // Reactivate submit button
}

$(document).ready(function () {
  app.init();
});